import React from "react"
import loadable from "@loadable/component"

// Components
const DotFlashing = loadable(() => import("../../loader/dotFlashing"))

const CheckoutBtn = ({ checkout, disabled, loading }) => {
  const handleCheckout = () => {
    window.location.replace(
      checkout.webUrl.replace(
        "https://lili-cadez-presets.myshopify.com/",
        "https://checkout.influspace.agency/"
      )
    )
  }

  return (
    <button
      onClick={handleCheckout}
      disabled={disabled}
      className="py-4 w-full h-14 mt-5 px-12 font-semibold text-center transition duration-200 ease-in-out bg-primary-80 hover:bg-primary-110 shadow"
    >
      {loading ? <DotFlashing /> : "Pojdi na plačilo"}
    </button>
  )
}

export default CheckoutBtn
