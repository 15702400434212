import React from "react"

// utils
import { formatPrice } from "../../../utils/format-price"

const TotalPrice = ({ total, currencyCode }) => {
  return (
    <div className="flex items-center justify-between">
      <p className="font-semibold">Skupni Znesek</p>
      <p className="font-semibold">{formatPrice(currencyCode, total)}</p>
    </div>
  )
}

export default TotalPrice
