import React from "react"

// Components
import PaymentOptions from "../paymentOptions/index"

const PaymentMethods = () => {
  return (
    <div className="pt-4">
      <span className="block font-semibold mb-3 uppercase">
        MOŽNI NAČINI PLAČILA
      </span>
      <PaymentOptions />
      <span className="text-sm font-semibold mt-3 block">
        Plačilo preko UPN - Pošlji mi email na presets@lilicadez.com ali me
        kontaktiraj na Instagramu @lilicadezpresets
      </span>
    </div>
  )
}

export default PaymentMethods
