import React, { useState } from "react"
import loadable from "@loadable/component"

//  Components
import TotalPrice from "./totalPrice"
import CheckoutBtn from "./checkoutBtn"
import PaymentMethods from "../../productInfo/paymentMethods"
import Checkbox from "./checkbox"
const Discounts = loadable(() => import("./discounts"))

const Summary = ({ checkout, emptyCart, loading }) => {
  const [check, toggleCheck] = useState(false)
  return (
    <div className="mt-6 sm:flex justify-end">
      <div>
        <div className="grid  grid-cols-1 gap-3">
          {(checkout?.discountApplications?.length &&
            checkout?.discountApplications[0]?.applicable) ||
          checkout?.discountApplications[0]?.type?.name ===
            "AutomaticDiscountApplication" ? (
            <Discounts discounts={checkout.discountApplications} />
          ) : null}
          <TotalPrice
            total={checkout.totalPriceV2.amount}
            currencyCode={checkout.totalPriceV2.currencyCode}
          />

          <p className="text-base">
            Kode za popust se lahko unovčijo pri naslednjem koraku pri plačilu.
          </p>
        </div>
        <Checkbox toggleCheck={toggleCheck} check={check} />
        <CheckoutBtn
          checkout={checkout}
          disabled={loading || !check}
          loading={loading}
        />
        <div className="max-w-sm">
          <PaymentMethods />
        </div>
      </div>
    </div>
  )
}

export default Summary
