import React from "react"
import { Link } from "gatsby"

const Checkbox = ({ toggleCheck, check }) => {
  return (
    <div className="flex w-full mt-4 items-center">
      <input
        type="checkbox"
        name="terms"
        id="terms"
        className="mr-3"
        onChange={() => toggleCheck(!check)}
      />
      <label htmlFor="terms" className="text-sm text-left cursor-pointer">
        Prebral/-a sem in strinjam se s{" "}
        <Link to="/terms-of-use" className="underline text-blue-600">
          pogoji poslovanja
        </Link>{" "}
        spletne strani <span className="text-red-600">*</span>
      </label>
    </div>
  )
}

export default Checkbox
